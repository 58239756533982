import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/kaes_blog_header.png");
const header_image_mobile = require("../../../assets/img/blogs/kaes_blog_header_mob.png");
const section_1 = require("../../../assets/img/blogs/aiitsm_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/aiitsm_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/aiitsm_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/aiitsm_blog_image_4.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="How IT Support Automation Transforms the Digital Workplace Experience"
        description="Overcoming challenges and seizing opportunities with AI copilots. IT leaders discover best practices and strategies for successful implementation."
        keywords={[
          "ai for itsm, ai itsm, itsm, itsm ai, ai in itsm, ai service management, artificial intelligence service management",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt striking_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : ""
                }`}
              >
                {" "}
                <h1 className="font-page-header-home-blog color-white">
                  AI Copilots: What IT Leaders Need to Know (2025)
                </h1>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is an enterprise AI copilot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why do you need an enterprise co-pilot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How do you ensure success for enterprise AI co-pilot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How should enterprise search work for AI co-pilot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. What automated actions can the AI copilot unleash for the
                  service desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. What does an AI copilot do to improve productivity?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Beyond a generic solution: a must-have to drive AI copilot
                  success
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot is widely used across many domains for productivity
                and efficiency. According to two statistics from{" "}
                <a href="https://www.microsoft.com/en-us/education/blog/2024/05/new-idc-infobrief-explores-key-skills-and-tools-critical-to-ai-success/">
                  IDC InfoBrief,
                </a>
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  65% of LOB managers
                </span>{" "}
                choose Microsoft Copilot as the most critical solution for their
                teams' professional success.{" "}
                <a href="https://www.microsoft.com/en-us/worklab/work-trend-index/copilots-earliest-users-teach-us-about-generative-ai-at-work#:~:text=Copilot%20users%20were%2044%25%20more,Copilot%20made%20them%20more%20productive.">
                  Work Trend Index Special Report
                </a>{" "}
                also claims that{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  77% of copilot users&nbsp;
                </span>
                didn’t want to give it up once they started using it.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Ever since GitHub and Microsoft spearheaded the widespread
                popularity of copilots, every business has wanted to have its
                version of copilot for workplace productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But what do IT leaders do to facilitate{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-user-support-build-vs-buy">
                  AI copilot success?
                </a>{" "}
                Let’s discover what you can do as a business leader to drive
                copilot success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here is our IT leaders’ guide to help you learn and implement
                the right strategy for AI copilot success.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an enterprise AI copilot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a very surface-level concept, a copilot sits next to the
                pilot and assists him in maneuvering various flight operations
                activities. Similarly, An AI copilot is an AI-powered assistant
                that helps you work more efficiently and effectively. It
                alleviates the workload by providing the right information,
                contextual support, and additional functionalities by fetching
                data from enterprise-grade systems or applications.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In service desks, an AI-powered copilot is designed to answer
                employee questions, improve relevance and accuracy, and
                eliminate dependence on agents. Thus, an AI copilot improves the
                mean time to respond, minimizes the impact of downtime, and
                enhances productivity.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need an enterprise co-pilot?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise workflows are cumbersome, repetitive, and mundane.
                Employees spend too much time accomplishing these tasks. Yet,
                one mistake can mean delays and a backlog of tasks for them. AI
                copilot boosts employee efficiency and resolves workplace issues
                in real time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot helps you accomplish various service desk objectives
                —
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1. Answers for routine queries -&nbsp;
                  </span>
                  AI copilot provides relevant and accurate answers in simple
                  languages by understanding the intent behind the NLP queries.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2. Automation for routine tasks -&nbsp;
                  </span>
                  Your service desks can utilize an AI copilot to generate
                  responses and craft AI summaries to improve communications and
                  the level of support. Employees can retrieve knowledge
                  articles in consolidated forms and save time on problem
                  resolutions.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    3. Streamlining mundane workflows -&nbsp;
                  </span>
                  AI copilot can interact with your systems, streamline
                  back-and-forth workflows, and automate cross-functional
                  activities.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks can save time in fulfilling employee requests by
                using AI copilot, which speeds up response, coordination, and
                problem-solving for every stakeholder associated with IT
                support. But, the catch is if your AI copilot unleashes what you
                wish to gain for your workplace productivity. It largely depends
                on the implementation strategies of your AI copilot.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How do you ensure success for enterprise AI co-pilot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Under the fold, the truth is that an AI copilot is only
                effective when it can unleash the desired results. To gain
                desired objectives from your AI copilot, you must ensure the
                effectiveness of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  search, automation, and productivity capabilities.
                </span>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The service desk is designed to improve support and enhance
                employee or customer experience. AI has brought efficiency to
                some extent, but not entirely. AI copilot is an excellent
                extension of your service desk to automate end-to-end responses
                and service fulfillment.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Improving three core components—search, automation, and
                productivity—is a great way to ensure the AI copilot's
                effectiveness and enable its success. These components are
                essential for various reasons for service desks and employee
                support.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1. Search -&nbsp;
                  </span>
                  Enterprise search gives you answers that your employees need
                  to solve domain-specific problems. AI copilot can help you
                  achieve this purpose.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2. Automation -&nbsp;
                  </span>
                  AI copilot can facilitate actions and drive automation when
                  armed with knowledge articles.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    3. Productivity -&nbsp;
                  </span>
                  AI copilot, combined with search and automation, improves
                  knowledge discovery and information retrieval, thereby
                  improving problem resolution.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                As an IT leader, your core responsibility would be to check if
                your AI copilot provides these conveniences through enterprise
                search, automation, and productivity.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How should enterprise search work for AI co-pilot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise search differs from typical search functions on the
                internet, which randomly provides keyword-based search results.
                Enterprise search always takes care of domain-specific knowledge
                to give employees the exact information they need to solve a
                problem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, suppose an employee needs to know the setup
                configurations for his specific VPN tools. In that case, an AI
                copilot can help him find information. If the typical search
                function had to provide this answer, there would have been a
                couple of options that misleads other than help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilots use a variety of components to improve search
                functionalities and improve the effectiveness of enterprise
                workflows for your help desk or service desk.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  AI copilots use NLP to parse the helpdesk query, understand
                  the intent, and provide the relevant answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Machine learning used in AI copilot uses algorithms to analyze
                  user behavior, learn from past interactions, and continuously
                  improve search relevance for your employee support.
                </li>

                <li className="font-section-normal-text-testimonials">
                  An AI copilot can be trained to use knowledge graphs to
                  distinguish between various entities, which can provide
                  insightful answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The combination of conversational AI helps AI copilot engage
                  in back-and-forth dialogs with humans and provide custom
                  answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrating enterprise systems improves AI copilots' efficacy
                  and search efficiency for custom and unique questions.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Workativ’s Knowledge AI
                </span>{" "}
                is a perfect tool to turn your conversational AI into an
                AI-powered copilot. It allows you to train your platform with
                necessary knowledge repositories and gain hybrid NLU
                capabilities to improve search results. With domain-specificity,
                your employees can find answers to workplace-related queries and
                gain auto-resolution capabilities. Using Knowledge AI
                integration with your conversational AI chatbot, your AI copilot
                makes finding information on HR, IT, and operations easy.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Knowledge AI advantage for employees and service desks:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot built on Knowledge AI can dramatically help employees
                and service desks—
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What automated actions can the AI copilot unleash for the
                service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot uses Generative AI, AI, ML, and conversational AI to
                automate routine tasks by improving intent and context,
                streamlining employee self-service. These technologies allow AI
                copilots to narrow search results to more specific information,
                automate repetitive tasks, and free up employees for strategic
                tasks. By improving the intent and context of a search query
                through continuous learning, AI copilot can improve automation
                and transform{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/employee-onboarding-knowledge-ai">
                  employee self-service.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, an employee asks the AI copilot how I can access MS
                Office. AI copilot would ask for approval from the manager and
                provide him access. With automated actions, AI copilot can
                streamline workflows and improve employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Widespread use cases an AI copilot can enable include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  1. Incident triage - AI Copilot uses NLP to analyze incident
                  descriptions and assign them to appropriate teams to handle
                  incidents.
                </li>
                <li className="font-section-normal-text-testimonials">
                  2. Knowledge management - With AI Copilot, it is easy to
                  analyze incident resolutions and extract essential information
                  to create new knowledge base articles.
                </li>
                <li className="font-section-normal-text-testimonials">
                  3. Workflow management - AI copilot can ensure timely
                  processing of requests and routing them to the right team
                  based on priority.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s conversational AI unleashes the power of large
                language models and helps IT leaders turn conversational AI
                platforms into powerful AI copilots to automate various help
                desk tasks, such as password resets, software installs, and user
                deprovision.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What does an AI copilot do to improve productivity?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot can combine search and automated action to improve
                productivity and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-transformation-guide-gen-ai?distinct_id=%2524device%253A191e4aa896a7f8-074c119c2d95ff-17525637-157188-191e4aa896a7f8">
                  streamline support.
                </a>{" "}
                By streamlining information retrieval processes and automating
                routine tasks, AI copilot helps you achieve unprecedented
                productivity for your support teams and employees.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot’s intelligence and LLM capabilities provide excellent
                results, which include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1. Faster problem-solving:&nbsp;
                  </span>
                  Copilots can quickly categorize and prioritize problems, find
                  solutions, and automate routine tasks. This means issues are
                  solved much faster.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2. More productive teams:&nbsp;
                  </span>
                  Copilots can handle routine tasks so that teams can focus on
                  more important work. This makes everyone more productive.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    3. Improved knowledge base:&nbsp;
                  </span>
                  Copilots can automatically create and update a knowledge base
                  so that people can find answers themselves.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    4. Enhanced operations:&nbsp;
                  </span>
                  Copilots can help different departments work together better
                  and automate tasks, making everything more efficient.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    5. Data-driven results:&nbsp;
                  </span>
                  Copilots can show how quickly problems are solved and how much
                  time teams save.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Rather than relying on a typical copilot, leveraging custom
                solutions like Workativ’s Knowledge AI-based copilot can improve
                productivity by accelerating autonomous problem-solving
                capabilities.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Beyond a generic solution: a must-have to drive AI copilot
                success
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot drives business demands by facilitating the
                automation of routine tasks. IT leaders are well aware of the
                overwhelming workflows that bombard IT teams. AI copilot comes
                to the rescue and provides much-needed relief to employees and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/virtual-agent-powered-it-helpdesk">
                  service desk agents.
                </a>{" "}
                But, a typical AI copilot can handle only generic queries, not
                custom ones. If you wish to drive AI copilot success in tandem
                with your workplace’s objectives, you must ensure your AI
                copilot tool incorporates enterprise-grade search, automation,
                and productivity capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ can help you at a large scale with its LLM and
                Generative AI technology to give you the power of hybrid NLU and
                elevate the search experience.{" "}
                <a href="https://calendly.com/workativ/workativ-assistant-demo-page">
                  Book a demo today.{" "}
                </a>
              </p>

              <BlogCta
                ContentCta="Optimize Your IT Service Management with AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is an AI copilot for service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An AI copilot is an LLM-powered or AI-powered assistant for
                service desks to help automate routine tasks, solve employee
                problems for everyday tasks, and increase productivity and
                efficiency.
              </p>

              <h3 className="font-section-sub-header-small">
                2. What is the biggest challenge in implementing AI copilot
                success for service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks have a growing problem with unprecedented tickets,
                which need auto-resolutions for routine tasks. Custom AI
                copilots could offer solutions to the challenge. Unfortunately,
                most AI copilot possesses limited knowledge access and provide
                personalized responses to solve service desk problems.
              </p>

              <h3 className="font-section-sub-header-small">
                3. How can you ensure the success of AI copilot implementation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The integration of massive repository corpora, including
                organization-wide knowledge for enterprise search, automated
                actions, and productivity, can ensure the effectiveness of AI
                copilot success.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How does enterprise AI copilot aid in employee support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee support includes various routine tasks such as password
                resets, account unlocks, software installation, software
                upgrades, notification reminders, employee onboarding, leave
                inquiries, and so on, which are easy to solve with AI copilot’s
                assistance.
              </p>
              <h3 className="font-section-sub-header-small">
                5. What is a simple method to drive the AI copilot’s success?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When combined with enterprise search, automation, and
                productivity, AI copilot can unleash massive potential for task
                automation. Workativ’s Knowledge AI can fulfill this objective
                by allowing AI copilot to generate custom responses.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}

function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is an enterprise AI copilot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why do you need an enterprise co-pilot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How do you ensure success for enterprise AI co-pilot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How should enterprise search work for AI co-pilot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. What automated actions can the AI copilot unleash for the
                    service desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. What does an AI copilot do to improve productivity?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Beyond a generic solution: a must-have to drive AI
                    copilot success
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot is widely used across many domains for productivity
                and efficiency. According to two statistics from{" "}
                <a href="https://www.microsoft.com/en-us/education/blog/2024/05/new-idc-infobrief-explores-key-skills-and-tools-critical-to-ai-success/">
                  IDC InfoBrief,
                </a>
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  65% of LOB managers
                </span>{" "}
                choose Microsoft Copilot as the most critical solution for their
                teams' professional success.{" "}
                <a href="https://www.microsoft.com/en-us/worklab/work-trend-index/copilots-earliest-users-teach-us-about-generative-ai-at-work#:~:text=Copilot%20users%20were%2044%25%20more,Copilot%20made%20them%20more%20productive.">
                  Work Trend Index Special Report
                </a>{" "}
                also claims that{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  77% of copilot users&nbsp;
                </span>
                didn’t want to give it up once they started using it.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Ever since GitHub and Microsoft spearheaded the widespread
                popularity of copilots, every business has wanted to have its
                version of copilot for workplace productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But what do IT leaders do to facilitate{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-user-support-build-vs-buy">
                  AI copilot success?
                </a>{" "}
                Let’s discover what you can do as a business leader to drive
                copilot success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here is our IT leaders’ guide to help you learn and implement
                the right strategy for AI copilot success.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an enterprise AI copilot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a very surface-level concept, a copilot sits next to the
                pilot and assists him in maneuvering various flight operations
                activities. Similarly, An AI copilot is an AI-powered assistant
                that helps you work more efficiently and effectively. It
                alleviates the workload by providing the right information,
                contextual support, and additional functionalities by fetching
                data from enterprise-grade systems or applications.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In service desks, an AI-powered copilot is designed to answer
                employee questions, improve relevance and accuracy, and
                eliminate dependence on agents. Thus, an AI copilot improves the
                mean time to respond, minimizes the impact of downtime, and
                enhances productivity.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need an enterprise co-pilot?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise workflows are cumbersome, repetitive, and mundane.
                Employees spend too much time accomplishing these tasks. Yet,
                one mistake can mean delays and a backlog of tasks for them. AI
                copilot boosts employee efficiency and resolves workplace issues
                in real time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot helps you accomplish various service desk objectives
                —
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1. Answers for routine queries -&nbsp;
                  </span>
                  AI copilot provides relevant and accurate answers in simple
                  languages by understanding the intent behind the NLP queries.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2. Automation for routine tasks -&nbsp;
                  </span>
                  Your service desks can utilize an AI copilot to generate
                  responses and craft AI summaries to improve communications and
                  the level of support. Employees can retrieve knowledge
                  articles in consolidated forms and save time on problem
                  resolutions.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    3. Streamlining mundane workflows -&nbsp;
                  </span>
                  AI copilot can interact with your systems, streamline
                  back-and-forth workflows, and automate cross-functional
                  activities.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks can save time in fulfilling employee requests by
                using AI copilot, which speeds up response, coordination, and
                problem-solving for every stakeholder associated with IT
                support. But, the catch is if your AI copilot unleashes what you
                wish to gain for your workplace productivity. It largely depends
                on the implementation strategies of your AI copilot.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How do you ensure success for enterprise AI co-pilot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Under the fold, the truth is that an AI copilot is only
                effective when it can unleash the desired results. To gain
                desired objectives from your AI copilot, you must ensure the
                effectiveness of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  search, automation, and productivity capabilities.
                </span>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The service desk is designed to improve support and enhance
                employee or customer experience. AI has brought efficiency to
                some extent, but not entirely. AI copilot is an excellent
                extension of your service desk to automate end-to-end responses
                and service fulfillment.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Improving three core components—search, automation, and
                productivity—is a great way to ensure the AI copilot's
                effectiveness and enable its success. These components are
                essential for various reasons for service desks and employee
                support.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1. Search -&nbsp;
                  </span>
                  Enterprise search gives you answers that your employees need
                  to solve domain-specific problems. AI copilot can help you
                  achieve this purpose.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2. Automation -&nbsp;
                  </span>
                  AI copilot can facilitate actions and drive automation when
                  armed with knowledge articles.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    3. Productivity -&nbsp;
                  </span>
                  AI copilot, combined with search and automation, improves
                  knowledge discovery and information retrieval, thereby
                  improving problem resolution.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                As an IT leader, your core responsibility would be to check if
                your AI copilot provides these conveniences through enterprise
                search, automation, and productivity.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How should enterprise search work for AI co-pilot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise search differs from typical search functions on the
                internet, which randomly provides keyword-based search results.
                Enterprise search always takes care of domain-specific knowledge
                to give employees the exact information they need to solve a
                problem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, suppose an employee needs to know the setup
                configurations for his specific VPN tools. In that case, an AI
                copilot can help him find information. If the typical search
                function had to provide this answer, there would have been a
                couple of options that misleads other than help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilots use a variety of components to improve search
                functionalities and improve the effectiveness of enterprise
                workflows for your help desk or service desk.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  AI copilots use NLP to parse the helpdesk query, understand
                  the intent, and provide the relevant answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Machine learning used in AI copilot uses algorithms to analyze
                  user behavior, learn from past interactions, and continuously
                  improve search relevance for your employee support.
                </li>

                <li className="font-section-normal-text-testimonials">
                  An AI copilot can be trained to use knowledge graphs to
                  distinguish between various entities, which can provide
                  insightful answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The combination of conversational AI helps AI copilot engage
                  in back-and-forth dialogs with humans and provide custom
                  answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Integrating enterprise systems improves AI copilots' efficacy
                  and search efficiency for custom and unique questions.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Workativ’s Knowledge AI
                </span>{" "}
                is a perfect tool to turn your conversational AI into an
                AI-powered copilot. It allows you to train your platform with
                necessary knowledge repositories and gain hybrid NLU
                capabilities to improve search results. With domain-specificity,
                your employees can find answers to workplace-related queries and
                gain auto-resolution capabilities. Using Knowledge AI
                integration with your conversational AI chatbot, your AI copilot
                makes finding information on HR, IT, and operations easy.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Knowledge AI advantage for employees and service desks:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot built on Knowledge AI can dramatically help employees
                and service desks—
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What automated actions can the AI copilot unleash for the
                service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot uses Generative AI, AI, ML, and conversational AI to
                automate routine tasks by improving intent and context,
                streamlining employee self-service. These technologies allow AI
                copilots to narrow search results to more specific information,
                automate repetitive tasks, and free up employees for strategic
                tasks. By improving the intent and context of a search query
                through continuous learning, AI copilot can improve automation
                and transform{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/employee-onboarding-knowledge-ai">
                  employee self-service.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, an employee asks the AI copilot how I can access MS
                Office. AI copilot would ask for approval from the manager and
                provide him access. With automated actions, AI copilot can
                streamline workflows and improve employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Widespread use cases an AI copilot can enable include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  1. Incident triage - AI Copilot uses NLP to analyze incident
                  descriptions and assign them to appropriate teams to handle
                  incidents.
                </li>
                <li className="font-section-normal-text-testimonials">
                  2. Knowledge management - With AI Copilot, it is easy to
                  analyze incident resolutions and extract essential information
                  to create new knowledge base articles.
                </li>
                <li className="font-section-normal-text-testimonials">
                  3. Workflow management - AI copilot can ensure timely
                  processing of requests and routing them to the right team
                  based on priority.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s conversational AI unleashes the power of large
                language models and helps IT leaders turn conversational AI
                platforms into powerful AI copilots to automate various help
                desk tasks, such as password resets, software installs, and user
                deprovision.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What does an AI copilot do to improve productivity?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot can combine search and automated action to improve
                productivity and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-transformation-guide-gen-ai?distinct_id=%2524device%253A191e4aa896a7f8-074c119c2d95ff-17525637-157188-191e4aa896a7f8">
                  streamline support.
                </a>{" "}
                By streamlining information retrieval processes and automating
                routine tasks, AI copilot helps you achieve unprecedented
                productivity for your support teams and employees.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot’s intelligence and LLM capabilities provide excellent
                results, which include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1. Faster problem-solving:&nbsp;
                  </span>
                  Copilots can quickly categorize and prioritize problems, find
                  solutions, and automate routine tasks. This means issues are
                  solved much faster.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2. More productive teams:&nbsp;
                  </span>
                  Copilots can handle routine tasks so that teams can focus on
                  more important work. This makes everyone more productive.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    3. Improved knowledge base:&nbsp;
                  </span>
                  Copilots can automatically create and update a knowledge base
                  so that people can find answers themselves.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    4. Enhanced operations:&nbsp;
                  </span>
                  Copilots can help different departments work together better
                  and automate tasks, making everything more efficient.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    5. Data-driven results:&nbsp;
                  </span>
                  Copilots can show how quickly problems are solved and how much
                  time teams save.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Rather than relying on a typical copilot, leveraging custom
                solutions like Workativ’s Knowledge AI-based copilot can improve
                productivity by accelerating autonomous problem-solving
                capabilities.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Beyond a generic solution: a must-have to drive AI copilot
                success
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI copilot drives business demands by facilitating the
                automation of routine tasks. IT leaders are well aware of the
                overwhelming workflows that bombard IT teams. AI copilot comes
                to the rescue and provides much-needed relief to employees and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/virtual-agent-powered-it-helpdesk">
                  service desk agents.
                </a>{" "}
                But, a typical AI copilot can handle only generic queries, not
                custom ones. If you wish to drive AI copilot success in tandem
                with your workplace’s objectives, you must ensure your AI
                copilot tool incorporates enterprise-grade search, automation,
                and productivity capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ can help you at a large scale with its LLM and
                Generative AI technology to give you the power of hybrid NLU and
                elevate the search experience.{" "}
                <a href="https://calendly.com/workativ/workativ-assistant-demo-page">
                  Book a demo today.{" "}
                </a>
              </p>

              <BlogCta
                ContentCta="Optimize Your IT Service Management with AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is an AI copilot for service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An AI copilot is an LLM-powered or AI-powered assistant for
                service desks to help automate routine tasks, solve employee
                problems for everyday tasks, and increase productivity and
                efficiency.
              </p>

              <h3 className="font-section-sub-header-small">
                2. What is the biggest challenge in implementing AI copilot
                success for service desks?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks have a growing problem with unprecedented tickets,
                which need auto-resolutions for routine tasks. Custom AI
                copilots could offer solutions to the challenge. Unfortunately,
                most AI copilot possesses limited knowledge access and provide
                personalized responses to solve service desk problems.
              </p>

              <h3 className="font-section-sub-header-small">
                3. How can you ensure the success of AI copilot implementation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The integration of massive repository corpora, including
                organization-wide knowledge for enterprise search, automated
                actions, and productivity, can ensure the effectiveness of AI
                copilot success.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How does enterprise AI copilot aid in employee support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee support includes various routine tasks such as password
                resets, account unlocks, software installation, software
                upgrades, notification reminders, employee onboarding, leave
                inquiries, and so on, which are easy to solve with AI copilot’s
                assistance.
              </p>
              <h3 className="font-section-sub-header-small">
                5. What is a simple method to drive the AI copilot’s success?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When combined with enterprise search, automation, and
                productivity, AI copilot can unleash massive potential for task
                automation. Workativ’s Knowledge AI can fulfill this objective
                by allowing AI copilot to generate custom responses.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT- Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
